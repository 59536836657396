import AppGuard from '@/components/shared/appGuard'
import BaseLayout from '@/components/shared/layouts/baseLayout'
import { NextPageWithLayout } from '@/types/nextPage'
import Link from 'next/link'

const Page: NextPageWithLayout = () => {
  return (
    <BaseLayout>
      <div className="mt-12 flex h-3/4 flex-col justify-items-center text-center">
        <h1 className="typography-h1 mb-3 font-bold">404 Page not found!</h1>
        <h3 className="typography-h3 mb-4">
          The page you try to access cannot be found!
        </h3>
        <Link className="text-blue-600 visited:text-purple-600" href="/">
          Go back home
        </Link>
      </div>
    </BaseLayout>
  )
}

Page.getLayout = (page) => <AppGuard>{page}</AppGuard>

export default Page
